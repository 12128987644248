import { Grid } from "@mui/material";
import React, { useState } from "react";
import { DefaultServicesIcon } from "../../constant/ImagesS3";
import { capitalizeWords, splitServiceNames } from "../../constant/utils";
import ViewServiceDialog from "./ViewServiceDialog";

function SubCategoryList({
  displayedCategories = [],
  searchFocused,
  filterServices,
  isPopular,
}) {
  const [isViewServiceDialogOpen, setIsServiceDialogOpen] = useState(false);
  const [isDialogService, setIsDialogService] = useState("");
  const handleDialogOpen = (service) => {
    setIsDialogService(service);
    setIsServiceDialogOpen(true);
  };

  return (
    <>
      {isPopular ? (
        <div className="subcategory-section">
          <div className="subcategory-heading-box">
            <div className="subcategory-heading-inner-box">
              <h6 className="subcategory-heading">Popular Services</h6>
            </div>
            <div className="services-horz-line" />
          </div>
          <Grid container spacing={2}>
            {displayedCategories?.map((service, index) => (
              <Grid
                item
                key={index}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className="custom-grid-padding"
              >
                <div
                  className="subcategory-items"
                  onClick={() => handleDialogOpen(service)}
                >
                  <img
                    src={service?.serviceIcon || DefaultServicesIcon}
                    alt={service?.serviceName}
                    className="services-card-img"
                  />
                  <div>{splitServiceNames(service?.serviceName)}</div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      ) : (
        <>
          {displayedCategories?.map((category, index) => (
            <div key={index} className="subcategory-container">
              {category?.subcategories?.map((subcategory) => {
                const filteredServices = filterServices(subcategory?.services);
                return (
                  <div
                    className="subcategory-section"
                    key={`${subcategory?.subcategoryId}-${subcategory?.subcategoryName}`}
                  >
                    <div className="subcategory-heading-box">
                      {filteredServices?.length > 0 && !searchFocused && (
                        <>
                          <div className="subcategory-heading-inner-box">
                            <h6 className="subcategory-heading">
                              {capitalizeWords(subcategory?.subcategoryName)}
                            </h6>
                          </div>
                          <div className="services-horz-line" />
                        </>
                      )}
                    </div>
                    <Grid container spacing={2}>
                      {filteredServices?.map((service, index) => (
                        <Grid
                          item
                          key={index}
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          className="custom-grid-padding"
                        >
                          <div
                            className="subcategory-items"
                            onClick={() => handleDialogOpen(service)}
                          >
                            <img
                              src={service?.serviceIcon || DefaultServicesIcon}
                              alt={service?.serviceName}
                              className="services-card-img"
                            />
                            <div>{splitServiceNames(service?.serviceName)}</div>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                );
              })}
            </div>
          ))}
        </>
      )}
      <ViewServiceDialog
        isDialogOpen={isViewServiceDialogOpen}
        handleClose={() => setIsServiceDialogOpen(false)}
        service={isDialogService}
      />
    </>
  );
}

export default SubCategoryList;
