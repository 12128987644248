import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  RecommendationFriendImg,
  RecommendationFriendMobImg,
  RecommendationTrustedImg,
  RecommendationTrustedLikeImg,
  RecommendationTrustedTextImg,
  RecommendationUserImg,
} from "../../constant/ImagesS3";
import "./RecommendationMatters.css";
function RecommendationMatters() {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const scrollContainerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const updateScrollButtons = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerRef.current;
      const threshold = 1;
      setCanScrollLeft(scrollLeft > threshold);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth - threshold);
    }
  };

  const scrollToNext = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 300;
      scrollContainerRef.current.scrollBy({
        left: direction === "right" ? scrollAmount : -scrollAmount,
        behavior: "smooth",
      });
      setTimeout(updateScrollButtons, 300);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", updateScrollButtons);
      updateScrollButtons();
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", updateScrollButtons);
      }
    };
  }, []);

  return (
    <section className="recommendation-matters-container" id="recommendations">
      <div className="recommendation-top-text-box">
        <h4 className="recommendation-matters-text">Recommendation Matters</h4>
        <h2 className="recommendation-heading">
          {/* Super app for all the needs where personal recommendations matter more than anything. */}
          {/* A friend's experience is worth more than endless online reviews. */}
          Real experiences from friends matter more than online reviews.
        </h2>
      </div>
      <div className="recommendation-images-main-box" ref={scrollContainerRef}>
        {/* Friends Section */}
        <div className="recommendation-friends-box">
          <div className="recommendation-friends-text-box">
            <img
              src={RecommendationUserImg}
              alt="user"
              className="recommended-user-img"
              loading="lazy"
            />
            <div className="recommended-friend-text">
              Build your network of trusted friends to share recommendations.
            </div>
          </div>
          <div className="recommended-friend-img-box">
            <img
              src={
                isSmallScreen
                  ? RecommendationFriendMobImg
                  : RecommendationFriendImg
              }
              alt="friends"
              className="recommended-friend-img"
              loading="lazy"
            />
          </div>
        </div>
        {/* Service Providers Section */}
        <div className="recommendation-service-providers-box">
          <img
            src={RecommendationTrustedImg}
            alt="trusted"
            className="recommendation-service-providers-img"
            loading="lazy"
          />
          <img
            src={RecommendationTrustedTextImg}
            alt="trusted"
            className="recommendation-service-text-img"
            loading="lazy"
          />
          <img
            src={RecommendationTrustedLikeImg}
            alt="like"
            className="recommendation-service-like-img"
            loading="lazy"
          />
          <div className="recommendation-providers-text-box">
            <div className="recommendation-providers-heading">
              Recommend Service Providers
            </div>
            <div className="recommendation-providers-subheading">
              Recommend deserving service providers to help them grow through
              your network.
            </div>
          </div>
        </div>
      </div>

      {isSmallScreen && (
        <>
          <button
            className={`scroll-button left-button ${
              canScrollLeft ? "active" : "inactive"
            }`}
            onClick={() => scrollToNext("left")}
            disabled={!canScrollLeft}
          >
            <NavigateBeforeIcon />
          </button>
          <button
            className={`scroll-button right-button ${
              canScrollRight ? "active" : "inactive"
            }`}
            onClick={() => scrollToNext("right")}
            disabled={!canScrollRight}
          >
            <NavigateNextIcon />
          </button>
        </>
      )}
    </section>
  );
}

export default RecommendationMatters;
