import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PropTypes from "prop-types";
import { useState } from "react";
import { getFAQSettings } from "../../constant/utils";
import {
  ArrowIcon,
  containerStyle,
  CustomAccordion,
  CustomAccordionDetails,
  CustomAccordionSummary,
} from "./styleSheet";

const FAQAccordion = ({ isUsersQue, isProvidersQue, data }) => {
  const [expandedPanel, setExpandedPanel] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };
  const settings = getFAQSettings(isUsersQue, isProvidersQue);
  const { bgColor, questions } = settings;
  return (
    <div style={containerStyle(bgColor)}>
      {data?.map((item, index) => (
        <CustomAccordion
          key={item?._id}
          isLast={index === questions?.length - 1}
          expanded={expandedPanel === item?._id}
          onChange={handleChange(item?._id)}
        >
          <CustomAccordionSummary
            expandIcon={
              <ArrowIcon>
                <KeyboardArrowDownIcon
                  sx={{
                    fontSize: 24,
                    color: "#6E6E73",
                    "@media (max-width:600px)": { fontSize: 24 },
                  }}
                />
              </ArrowIcon>
            }
            aria-controls={`panel${item?._id}-content`}
            id={`panel${item?._id}-header`}
            isFirst={index === 0}
          >
            {item?.question}
          </CustomAccordionSummary>
          <CustomAccordionDetails>{item?.answer}</CustomAccordionDetails>
        </CustomAccordion>
      ))}
    </div>
  );
};

FAQAccordion.propTypes = {
  isUsersQue: PropTypes.bool,
  isProvidersQue: PropTypes.bool,
  data: PropTypes.array,
};

export default FAQAccordion;
