import { useCallback, useEffect, useState } from "react";
import { ERROR_MESSAGES } from "../constant/messages";

const useCategoriesAndServices = (servicesStaticData) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const mapServices = useCallback(
    (services, categoryId, subcategoryId) =>
      services?.map((service) => ({
        ...service,
        categoryId,
        subcategoryId,
      })),
    []
  );

  const mapSubcategories = useCallback(
    (subcategories, categoryId) =>
      subcategories?.map((subcategory) => ({
        subcategoryId: subcategory?.subcategoryId,
        subcategoryName: subcategory?.subcategoryName,
        subcategoryImage: subcategory?.subcategoryImage,
        services: mapServices(
          subcategory?.services,
          categoryId,
          subcategory?.subcategoryId
        ),
      })),
    [mapServices]
  );

  const mapCategories = useCallback(
    (categories) =>
      categories?.map((category) => ({
        categoryId: category?.categoryId,
        categoryName: category?.categoryName,
        categoryImage: category?.categoryImage,
        subcategories: mapSubcategories(
          category?.subcategories,
          category?.categoryId
        ),
      })),
    [mapSubcategories]
  );

  const fetchCategoriesAndServices = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const fetchedCategories = mapCategories(servicesStaticData);
      setCategories(fetchedCategories);
    } catch (error) {
      console.error(ERROR_MESSAGES.fetchDataError, error);
      setError(ERROR_MESSAGES.fetchDataError);
    } finally {
      setLoading(false);
    }
  }, [servicesStaticData, mapCategories]);

  useEffect(() => {
    fetchCategoriesAndServices();
  }, [fetchCategoriesAndServices]);

  return {
    categories,
    loading,
    error,
  };
};

export default useCategoriesAndServices;
