import React, { useEffect } from "react";
import Layout from "../../Layout";
import AppServices from "../../components/AppServices/AppServices";
import Seo from "../../components/SEO/Seo";
import {
  modifiersSEOKeywords,
  servicesSEOKeywords,
} from "../../constant/staticData";
function Services() {
  const metaKeywords = servicesSEOKeywords?.flatMap((service) =>
    modifiersSEOKeywords?.flatMap((modifier) => [
      `${modifier} ${service} Near Me`,
    ])
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <Seo
        title="Bataiyo | Trusted Services"
        description="Discover and connect with trusted providers offering top services in major cities across India. Explore top services in Pune, Bangalore, Delhi, Mumbai, Hyderabad, Chennai, Kolkata, Ahmedabad, Jaipur, Chandigarh, and other cities throughout India. Enjoy zero-commission services for Daily Needs, Domestic Services, Retail & Shopping, Travel, and more on Bataiyo."
        keywords={metaKeywords.join(", ")}
        url="services"
      />
      <AppServices />
    </Layout>
  );
}

export default Services;
