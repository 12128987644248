import React, { memo, useState } from "react";
import { useDialog } from "../../context/DialogContext";
import CustomDialog from "../SignUpFormVerification/CustomDialog";
import "./HeroSection.css";
import { useMediaQuery } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  HeroCheckIcon,
  HeroSectionAppFrameImg,
  HeroSectionAppFrameMobImg,
} from "../../constant/ImagesS3";
import { heroSubheadings } from "../../constant/staticData";
const HeroSection = () => {
  const { handleDialogOpen } = useDialog();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [handleSuccessDialogOpen, setHandleSuccessDialogOpen] = useState(false);
  return (
    <>
      <section className="hero-section">
        <div className="hero-section-inner-box">
          <div className="hero-text-box">
            <h1 className="hero-heading">
              Get Trusted{" "}
              <span className="hero-blue-text">Recommendations</span> From Your
              Friends
            </h1>
            <div className="hero-subheading-box">
              {heroSubheadings?.map((text, index) => (
                <div className="hero-subheading-list" key={index}>
                  <div className="hero-subheading-icon">
                    <img
                      src={HeroCheckIcon}
                      alt="check-icon"
                      className="hero-check-img"
                      loading="lazy"
                    />
                  </div>
                  <h5 className="hero-subheading">{text}</h5>
                </div>
              ))}
            </div>
            <div className="hero-download-btn-box">
              <button
                className="hero-download-btn"
                onClick={() => setHandleSuccessDialogOpen(true)}
              >
                Download Now
                <ArrowForwardIosIcon className="right-arrow-icon" />
              </button>
            </div>{" "}
          </div>
          <div className="hero-img-box">
            <img
              src={
                isSmallScreen
                  ? HeroSectionAppFrameMobImg
                  : HeroSectionAppFrameImg
              }
              alt="group-img"
              className="hero-group-img"
              loading="lazy"
            />
          </div>
        </div>
      </section>
      {handleDialogOpen && (
        <CustomDialog
          isDialogOpen={handleSuccessDialogOpen}
          handleClose={() => setHandleSuccessDialogOpen(false)}
        />
      )}
    </>
  );
};

export default memo(HeroSection);
