import React from "react";
import { AllServicesIcon, PopularServicesIcon } from "../../constant/ImagesS3";
import { servicesStaticData } from "../../constant/servicesStaticData";
import { capitalizeWords } from "../../constant/utils";
import useCategoriesAndServices from "../../hooks/useCategoriesAndServices";

function CategoriesTabs({ selectedCategoryId, handleCategoryChange }) {
  const {
    categories,
    // loading,
  } = useCategoriesAndServices(servicesStaticData);

  return (
    <ul className="categories-list">
      <li
        className={`category-item ${
          selectedCategoryId === "all" ? "active" : ""
        }`}
        onClick={() => handleCategoryChange("all")}
      >
        <img
          src={AllServicesIcon}
          alt="all services"
          className={`service-category-all-img ${
            selectedCategoryId === "all" ? "active" : ""
          }`}
        />
        <div>All Services</div>
      </li>
      <li
        className={`category-item ${
          selectedCategoryId === "popular" ? "active" : ""
        }`}
        onClick={() => handleCategoryChange("popular")}
      >
        <img
          src={PopularServicesIcon}
          alt="popular"
          className="service-category-popular-img"
        />
        <div>Popular</div>
      </li>
      {categories?.map((category) => (
        <li
          key={category?.categoryId}
          className={`category-item ${
            selectedCategoryId === category?.categoryId ? "active" : ""
          }`}
          onClick={() => handleCategoryChange(category?.categoryId)}
        >
          <img
            src={category?.categoryImage}
            alt={category.categoryName}
            className={`service-category-img ${
              selectedCategoryId === category?.categoryId ? "active" : ""
            }`}
          />
          <div>{capitalizeWords(category?.categoryName)}</div>
        </li>
      ))}
    </ul>
  );
}

export default CategoriesTabs;
