import { ROUTES } from "../constant/routes";
const cloudfrontBaseUrl = ROUTES.CLOUDFRONT_URL;
export const ErrorImg = `${cloudfrontBaseUrl}closecircle.webp`;
export const BataiyoLoader = `${cloudfrontBaseUrl}bataiyoloader.gif`;
export const YouTubeImg = `${cloudfrontBaseUrl}youtubesuccessicon.webp`;
export const XImg = `${cloudfrontBaseUrl}twittersuccessicon.webp`;
export const ReducesSearchTimeImg = `${cloudfrontBaseUrl}reducesearchtimeimg.svg`;
export const OrangeBulbIcon = `${cloudfrontBaseUrl}orangelightbulbicon.svg`;
export const OnlyTrustedImg = `${cloudfrontBaseUrl}onlytrustedimg.svg`;
export const NoCommissionImg = `${cloudfrontBaseUrl}nocommissionimg.svg`;
export const LinkedInImg = `${cloudfrontBaseUrl}linkedinsuccessicon.webp`;
export const LightBulbIcon = `${cloudfrontBaseUrl}lightbulbiconwhite.svg`;
export const InstagramImg = `${cloudfrontBaseUrl}instagramsuccessicon.webp`;
export const InfiniteGreenIcon = `${cloudfrontBaseUrl}infinitegreenicon.svg`;
export const HandsIcon = `${cloudfrontBaseUrl}handsicon.svg`;
export const FacebookImg = `${cloudfrontBaseUrl}facebooksuccessicon.webp`;
export const ConnectionIcon = `${cloudfrontBaseUrl}connectionicon.svg`;
export const BookIcon = `${cloudfrontBaseUrl}bookicon.svg`;
export const SupportingBusinessBlogCoverImg = `${cloudfrontBaseUrl}blogsupportinglocalbusinesses.webp`;
export const MovingToCityBlogCoverImg = `${cloudfrontBaseUrl}blogmovingtocity.webp`;
export const ModernDayBlogCoverImg = `${cloudfrontBaseUrl}blogmodernday.webp`;
export const DiscoveringBlogCoverImg = `${cloudfrontBaseUrl}blogdiscoveringchandigarh.webp`;
export const DigitalDivideBlogCoverImg = `${cloudfrontBaseUrl}blogdigitaldivideimg.webp`;
export const BataiyoLogoIcon = `${cloudfrontBaseUrl}bataiyoroadmapicon.svg`;
export const BataiyoLogo = `${cloudfrontBaseUrl}bataiyologo.webp`;
export const BataiyoFooterLogo = `${cloudfrontBaseUrl}bataiyofooterlogo.svg`;
export const IndiaFlagLogo = `${cloudfrontBaseUrl}flagofIndia.svg`;
export const DefaultServicesIcon = `${cloudfrontBaseUrl}homesettingicon.svg`;
export const PlayStoreImg = `${cloudfrontBaseUrl}playstore.svg`;
export const AppStoreImg = `${cloudfrontBaseUrl}appstore.svg`;
export const AppleIcon = `${cloudfrontBaseUrl}appleicon.webp`;
export const featureCrossImg = `${cloudfrontBaseUrl}bluecrossicons.webp`;
export const GoogleIcon = `${cloudfrontBaseUrl}googleicon.webp`;
export const QRCode = `${cloudfrontBaseUrl}qrcode.webp`;
export const NavLogo = `${cloudfrontBaseUrl}navlogo.svg`;
export const WhatsAppImg = `${cloudfrontBaseUrl}whatsappicon.webp`;
export const DashboardImg = `${cloudfrontBaseUrl}jobdashboardimg4.webp`;
export const HireCategoriesImg = `${cloudfrontBaseUrl}hirecategories.webp`;
export const JobAppImg = `${cloudfrontBaseUrl}jobappimg.webp`;
export const JobHeroImg = `${cloudfrontBaseUrl}jobheroframe4.webp`;
export const JobMobHeroImg = `${cloudfrontBaseUrl}heromobimg3.webp`;
export const SuccessDialogImg = `${cloudfrontBaseUrl}dialogsuccessimg.webp`;
export const WarningImg = `${cloudfrontBaseUrl}whitealert.webp`;
export const OurPlatformHiringImg = `${cloudfrontBaseUrl}ourplatformhireimg.svg`;
export const HeroSectionAppFrameMobImg = `${cloudfrontBaseUrl}hero-mob-frame-sm.webp`;
export const HeroSectionAppFrameImg = `${cloudfrontBaseUrl}hero-mobile-frame-img2.webp`;
export const ExplorerSignupImg = `${cloudfrontBaseUrl}explorer-img.webp`;
export const ProviderSignupImg = `${cloudfrontBaseUrl}provider-img1.webp`;
export const HeroCheckIcon = `${cloudfrontBaseUrl}hero-check-icon.webp`;
export const PeerToPeerImg = `${cloudfrontBaseUrl}peer-to-peer-card-img1.webp`;
export const SuperAppCardImg = `${cloudfrontBaseUrl}super-app-card-img.webp`;
export const ZeroCommissionCardImg = `${cloudfrontBaseUrl}zero-commission-card-img1.webp`;
export const HotelOwner = `${cloudfrontBaseUrl}hotel-owner-profile.webp`;
export const GrowingTogetherIcon = `${cloudfrontBaseUrl}growing-together-icon.webp`;
export const CabProfile = `${cloudfrontBaseUrl}cab-profile.webp`;
export const NetworkMattersIcon = `${cloudfrontBaseUrl}networking-matters-icon.webp`;
export const DeliveryProfile = `${cloudfrontBaseUrl}delivery-profile.webp`;
export const RecommendationFriendImg = `${cloudfrontBaseUrl}recommended-friends-img.webp`;
export const RecommendationFriendMobImg = `${cloudfrontBaseUrl}recommended-friends-mob-img.webp`;
export const RecommendationTrustedTextImg = `${cloudfrontBaseUrl}recommended-trusted-text-img1.webp`;
export const RecommendationTrustedImg = `${cloudfrontBaseUrl}recommended-trusted-img2.webp`;
export const RecommendationTrustedLikeImg = `${cloudfrontBaseUrl}recommended-trusted-like-img.webp`;
export const RecommendationUserImg = `${cloudfrontBaseUrl}recommended-user-img.webp`;
export const RoadmapDotIcon = `${cloudfrontBaseUrl}roadmap-dot-img.webp`;
export const SuperAppAppStoreImg = `${cloudfrontBaseUrl}app-store-img.webp`;
export const SuperAppDownloadImg = `${cloudfrontBaseUrl}super-appmob-img.webp`;
export const SuperAppPlayStoreImg = `${cloudfrontBaseUrl}play-store-img.webp`;
export const TrustFirstIcon = `${cloudfrontBaseUrl}trust-first-icon.webp`;
export const AllServicesIcon = `${cloudfrontBaseUrl}all-services-icon.svg`;
export const SearchIcon = `${cloudfrontBaseUrl}search-normal-icon.png`;
export const PopularServicesIcon = `${cloudfrontBaseUrl}popular-services-icon.png`;
export const InstagramIconS = `${cloudfrontBaseUrl}instas-icon.svg`;
export const FacebookIconS = `${cloudfrontBaseUrl}fbs-icon.svg`;
export const LinkedInIconS = `${cloudfrontBaseUrl}linkedins-icon.svg`;
export const YouTubeIconS = `${cloudfrontBaseUrl}youtubes-icon.svg`;
export const XIconS = `${cloudfrontBaseUrl}xs-icon.svg`;
export const AppStoreS = `${cloudfrontBaseUrl}app-stores.svg`;
export const PlayStoreS = `${cloudfrontBaseUrl}play-stores.svg`;
