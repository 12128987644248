import { Grid, Skeleton } from "@mui/material";

function ServiceListSkeleton() {
  return (
    <>
      {Array.from(new Array(2)).map((_, index) => (
        <div
          key={index}
          className="subcategory-section"
          style={{ alignItems: "start" }}
        >
          <Skeleton variant="text" width={160} height={35} />
          <Grid container spacing={2}>
            {Array.from(new Array(12)).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={44}
                  style={{ borderRadius: "12px" }}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      ))}
    </>
  );
}
function CategoriesTabsSkeleton() {
  return (
    <div className="categories-list">
      {Array.from(new Array(10)).map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          style={{
            borderRadius: "8px",
            marginBottom: "8px",
          }}
          width={"100%"}
          height={44}
        />
      ))}
    </div>
  );
}

function MobileCategoriesTabsSkeleton() {
  return (
    <Skeleton
      variant="rectangular"
      style={{
        borderRadius: "12px",
      }}
      width={"100%"}
      height={50}
    />
  );
}

function SearchInputSkeleton() {
  return (
    <Skeleton
      variant="rectangular"
      style={{
        borderRadius: "12px",
      }}
      width={"100%"}
      height={46}
      className="services-search-box"
    />
  );
}

function TopHeadTextSkeleton() {
  return (
    <div className="services-text-box">
      <Skeleton
        variant="text"
        style={{ maxWidth: "400px" }}
        className="services-subheading"
      />
      <Skeleton
        variant="text"
        style={{ maxWidth: "800px" }}
        className="services-heading"
      />
    </div>
  );
}
export {
  ServiceListSkeleton,
  CategoriesTabsSkeleton,
  MobileCategoriesTabsSkeleton,
  SearchInputSkeleton,
  TopHeadTextSkeleton,
};
