import { useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";
import React, { useMemo } from "react";
import { useInView } from "react-intersection-observer";
import {
  PeerToPeerImg,
  SuperAppCardImg,
  ZeroCommissionCardImg,
} from "../../constant/ImagesS3";
import "./WhyBataiyo.css";

function WhyBataiyo() {
  const isMobile = useMediaQuery("(max-width:768px)");
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const cards = [
    <ZeroCommissionCard key="zero-commission" />,
    <PeerToPeerCard key="peer-to-peer" />,
    <SuperAppCard key="super-app" />,
  ];

  const animations = useMemo(() => {
    if (!isMobile) return [];
    return [
      {
        initial: { x: "-100vw" },
        animate: inView ? { x: 0 } : {},
        transition: { duration: 1.5 },
      },
      {
        initial: { x: "100vw" },
        animate: inView ? { x: 0 } : {},
        transition: { duration: 1.5 },
      },
      {
        initial: { x: "-100vw" },
        animate: inView ? { x: 0 } : {},
        transition: { duration: 1.5 },
      },
    ];
  }, [isMobile, inView]);

  return (
    <section className="why-bataiyo-container" id="why-us" ref={ref}>
      <h2 className="why-bataiyo-heading">Why Bataiyo?</h2>
      <div className="why-bataiyo-card-box">
        {cards.map((Card, index) =>
          isMobile ? (
            <motion.div
              key={index}
              className="why-bataiyo-card-inner-box"
              initial={animations[index]?.initial}
              animate={animations[index]?.animate}
              transition={animations[index]?.transition}
            >
              {Card}
            </motion.div>
          ) : (
            <div key={index} className="why-bataiyo-card-inner-box">
              {Card}
            </div>
          )
        )}
      </div>
    </section>
  );
}

const ZeroCommissionCard = () => {
  return (
    <div className="zero-commission-card-box">
      <img
        src={ZeroCommissionCardImg}
        alt="zero-commission"
        className="zero-commission-img"
        loading="lazy"
      />
      <div className="card-text-box">
        <div className="commission-card-heading">Zero Commission!</div>
        <div className="commission-card-subheading">
          #Trust is earned, not bought.
        </div>
      </div>
    </div>
  );
};

const PeerToPeerCard = () => {
  return (
    <div className="peer-to-peer-card-box">
      <div className="card-text-box">
        <div className="peer-to-peer-title">Peer-To-Peer Network!</div>
        <div className="peer-to-peer-description">
          Find help through your friends.{" "}
        </div>
      </div>
      <img
        src={PeerToPeerImg}
        alt="peer-to-peer"
        className="peer-to-peer-img"
        loading="lazy"
      />
    </div>
  );
};

const SuperAppCard = () => {
  return (
    <div className="super-app-card-box">
      <div className="super-app-title">
        Super App
        <br />
        for all your
        <br />
        needs!
      </div>
      <img
        src={SuperAppCardImg}
        alt="super-app"
        className="super-app-img"
        loading="lazy"
      />
    </div>
  );
};

export default WhyBataiyo;
