import React, { useRef } from "react";
import { capitalizeWords } from "../../constant/utils";
import "./AppServices.css";

function MobileCategoriesTabs({
  categories,
  selectedCategoryId,
  handleCategoryChange,
}) {
  const tabsScrollRef = useRef(null);

  const handleCategoryClick = (categoryId) => {
    handleCategoryChange(categoryId);

    const tabElements = tabsScrollRef.current?.children;
    const activeTabIndex = categories.findIndex(
      (category) => category?.categoryId === categoryId
    );

    if (tabElements && tabsScrollRef.current) {
      const tabElement =
        categoryId === "all"
          ? tabElements[0] // "All Services" tab
          : categoryId === "popular"
          ? tabElements[1] // "Popular" tab
          : tabElements[activeTabIndex + 2]; // Other categories (adjusted for "all" and "popular")

      if (tabElement) {
        // Calculate the scroll position to center the active tab
        const container = tabsScrollRef.current;
        const tabOffsetLeft = tabElement.offsetLeft;
        const tabWidth = tabElement.offsetWidth;
        const containerWidth = container.offsetWidth;

        const scrollTo = tabOffsetLeft - containerWidth / 2 + tabWidth / 2;
        container.scrollTo({
          left: scrollTo,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <div className="tabs-container">
      <div className="tabs-scroll" ref={tabsScrollRef}>
        <div
          className={`tab-item ${selectedCategoryId === "all" ? "active" : ""}`}
          onClick={() => handleCategoryClick("all")}
        >
          All Services
        </div>
        <div
          className={`tab-item ${
            selectedCategoryId === "popular" ? "active" : ""
          }`}
          onClick={() => handleCategoryClick("popular")}
        >
          Popular
        </div>
        {categories.map((category) => (
          <div
            key={category?.categoryId}
            className={`tab-item ${
              selectedCategoryId === category?.categoryId ? "active" : ""
            }`}
            onClick={() => handleCategoryClick(category?.categoryId)}
          >
            {capitalizeWords(category?.categoryName)}
          </div>
        ))}
      </div>
    </div>
  );
}

export default MobileCategoriesTabs;
