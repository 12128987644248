import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const OtpContainer = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "28px",
  padding: "24px",
};
const OtpHeadingBox = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "start",
  gap: "20px",
  width: "100%",
};
const OtpHeading = {
  textAlign: "start",
  fontSize: "20px",
  fontWeight: 500,
  fontFamily: "Outfit",
  lineHeight: "25px",
  color: "#000000",
  marginBottom: "4px",
};
const OtpSubheading = {
  textAlign: "start",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  color: "#7A7A7A",
  width: "100%",
  fontFamily: "Outfit",
};
const OtpSubheadingSpan = {
  color: "#000000",
};
const OtpDescription = {
  textAlign: "center",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  color: "#7A7A7A",
  width: "100%",
  fontFamily: "Outfit",
};
const OtpInputBox = {
  display: "flex",
  justifyContent: "center",
  width: "100%",
};
export const OtpField = styled(TextField)(({ theme }) => ({
  marginRight: theme.spacing(1),
  width: "72px",
  height: "65px",
  textAlign: "center",
  backgroundColor: "#F5F5F5",
  borderRadius: "24px",
  fontFamily: "Outfit",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "& input": {
      textAlign: "center",
      fontSize: "24px",
      height: "100%",
      padding: "18px 10px",
    },
  },
}));
const OtpButtonBox = { width: "100%" };

const OtpButton = {
  backgroundColor: "#0072DD",
  color: "#FFFFFF",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "20px",
  borderRadius: "100px",
  fontFamily: "Outfit",
  width: "100%",
  padding: "16px 0",
  textTransform: "none",
  "&:hover": {
    opacity: 0.9,
    backgroundColor: "#0072DD",
  },
  "&.Mui-disabled": {
    color: "#FFFFFF",
    backgroundColor: "#0072DD",
  },
};
export const CustomDialogBox = (isSmallScreen) => ({
  "& .MuiPaper-root": {
    width: isSmallScreen ? "90%" : "400px",
    height: "auto",
    borderRadius: "16px",
    display: "flex",
    alignItems: "center",
    spaceBetween: "justifyContent",
    padding: isSmallScreen ? "12px 24px" : "24px",
    margin: "0 12px",
  },
});
export const CustomDialogHeading = {
  textAlign: "center",
  color: "#000000",
  width: "100%",
  fontFamily: "Outfit",
  fontSize: "32px",
  lineHeight: "40px",
  fontWeight: "500",
};
export const CustomAlertDialogBox = {
  "& .MuiPaper-root": {
    maxWidth: "295px",
    height: "auto",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    spaceBetween: "justifyContent",
    padding: "24px",
    gap: "32px",
  },
};
export const CustomDialogAlertText = {
  textAlign: "center",
  color: "#d32f2f",
  width: "100%",
  fontFamily: "Outfit",
  fontSize: "18px",
  lineHeight: "18px",
  fontWeight: "400",
};
export const CustomDialogAlertImage = { width: "48px", height: "48px" };
export const CustomDialogContent = {
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  flexDirection: "column",
  gap: "12px",
  padding: "0",
  width: "100%",
};

export const CustomDialogText = {
  textAlign: "center",
  color: "#0072DD",
  width: "100%",
  fontFamily: "Outfit",
  fontSize: "24px",
  lineHeight: "30px",
  fontWeight: "500",
};
export const CustomDialogDescText = {
  textAlign: "center",
  color: "#525252",
  width: "100%",
  fontFamily: "Outfit",
  fontSize: "14px",
  lineHeight: "18px",
  fontWeight: "500",
  marginTop: "8px",
};
const backdropStyles = {
  backdropFilter: "blur(3px)",
  backgroundColor: "rgba(0,0,0,0.5)",
};
const resendOtpButton = {
  fontFamily: "Outfit",
  background: "none",
  border: "none",
  color: "#0072DD",
  cursor: "pointer",
};
export const CloseButtonBox = {
  width: "100%",
  padding: "0",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  height: "48px",
};
export const CloseButtonStyle = {
  fontFamily: "Outfit",
  fontWeight: "500",
  fontSize: "16px",
  background: "none",
  lineHeight: "20px",
  border: "1px solid #000000",
  color: "#000000",
  cursor: "pointer",
  borderRadius: "100px",
  padding: "13px 0",
  width: "100%",
  textTransform: "none",
  "&:hover": {
    opacity: 0.9,
    backgroundColor: "transparent",
  },
  "&.Mui-disabled": {
    color: "#000000",
  },
};
const circularProgressbar = { color: "white", marginRight: 8 };
const errorMessagesStyle = {
  fontFamily: "Outfit",
  alignSelf: "center",
  fontSize: "0.9rem",
};
const crossIconBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "5px",
};
const crossIcon = {
  fontSize: "18px",
  "&:hover": {
    cursor: "pointer",
  },
};
const otpDialogContainer = {
  "& .MuiDialog-paper": {
    borderRadius: "16px",
  },
};
const crossButtonBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  width: "100%",
};
const CustomDialogDescBox = {
  width: "100%",
  display: "flex",
  gap: "20px",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};
const CutomDialogText = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "5px",
  textAlign: "center",
  color: "#525252",
  width: "100%",
  fontFamily: "Outfit",
  fontSize: "16px",
  lineHeight: "20px",
  fontWeight: "500",
};
const CutomDialogImgBox = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "12px",
};
const CutomDialogFollowUsText = {
  color: "#525252",
  width: "100%",
  fontSize: "14px",
  lineHeight: "17px",
  fontWeight: "600",
  marginBottom: "12px",
  textAlign: "center",
};
const CutomDialogSocialIconsBox = {
  display: "flex",
  gap: "12px",
  width: "100%",
};
const CutomDialogSocialIconsImg = {
  width: "40px",
  height: "40px",
  cursor: "pointer",
};
const DialogContentBox = {
  display: "flex",
  gap: "8px",
  width: "100%",
  justifyContent: "start",
  alignItems: "baseline",
  marginBottom: "4px",
};
const DialogTextCircle = (color) => ({
  width: "8.5px",
  height: "8px",
  backgroundColor: color,
  borderRadius: "50%",
});
const DialogTextHead = {
  color: "#292929",
  width: "100%",
  fontFamily: "Outfit",
  fontSize: "14px",
  lineHeight: "20px",
  fontWeight: "700",
};
const DialogTextSpan = {
  color: "#525252",
  width: "100%",
  fontFamily: "Outfit",
  fontSize: "14px",
  lineHeight: "18px",
  fontWeight: "500",
};
const playStoreImg = {
  maxWidth: "100%",
  height: "44px",
  cursor: "pointer",
  "-webkit-tap-highlight-color": "transparent",
};
const usersText = {
  color: "#000000",
  fontWeight: "600",
};
const appleIcon = { height: "20px", width: "20px" };
const qrCodeImg = { height: "200px", width: "200px" };
const CustomDialogQrBox = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  margin: "8px 0",
};
const CustomServicesHeadBox = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "4px",
};
const CustomServicesHead = {
  textAlign: "center",
  color: "#525252",
  width: "100%",
  fontFamily: "Outfit",
  fontSize: "18px",
  lineHeight: "24px",
  fontWeight: "400",
};
const CustomServiceCard = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "12px",
  padding: "8px",
  borderRadius: "8px",
  maxWidth: "320px",
  width: "100%",
  backgroundColor: "#F5F5F5",
};
const CustomServiceCardImg = {
  width: "32px",
  height: "32px",
};
const CustomServiceCardName = {
  color: "#1D1D1F",
  fontSize: "14px",
  lineHeight: "18px",
  fontWeight: "400",
};
const CustomDialogServiceDesc = {
  color: "#525252",
  fontSize: "14px",
  lineHeight: "18px",
  fontWeight: "400",
  textAlign: "center",
  fontFamily: "Outfit",
};
const CustomServiceStoreImg = {
  width: "100%",
  height: "100%",
  maxWidth: "196px",
  maxHeight: "196px",
  cursor: "pointer",
};
const CustomDialogServiceSocialIcons = {
  width: "32px",
  height: "32px",
  cursor: "pointer",
  "-webkit-tap-highlight-color": "transparent",
};
const CustomDialogFollowUsText = {
  fontSize: "14px",
  lineHeight: "18px",
  fontWeight: "500",
  marginBottom: "12px",
  textAlign: "center",
  color: "#525252",
  fontFamily: "Outfit",
};
const CustomDialogSocialIconsLink = {
  dispay: "content",
};
export const styles = {
  OtpContainer,
  OtpHeadingBox,
  OtpHeading,
  CustomDialogHeading,
  OtpSubheading,
  OtpSubheadingSpan,
  OtpInputBox,
  OtpDescription,
  OtpButtonBox,
  OtpButton,
  backdropStyles,
  resendOtpButton,
  circularProgressbar,
  errorMessagesStyle,
  crossIconBox,
  crossIcon,
  otpDialogContainer,
  CustomDialogBox,
  CloseButtonBox,
  CustomDialogContent,
  CustomDialogText,
  crossButtonBox,
  CustomDialogDescText,
  CustomDialogDescBox,
  CutomDialogText,
  CutomDialogImgBox,
  CutomDialogFollowUsText,
  CutomDialogSocialIconsBox,
  CutomDialogSocialIconsImg,
  CloseButtonStyle,
  DialogContentBox,
  DialogTextCircle,
  DialogTextHead,
  DialogTextSpan,
  CustomDialogAlertText,
  CustomAlertDialogBox,
  CustomDialogAlertImage,
  playStoreImg,
  usersText,
  appleIcon,
  qrCodeImg,
  CustomDialogQrBox,
  CustomServicesHeadBox,
  CustomServicesHead,
  CustomServiceCard,
  CustomServiceCardImg,
  CustomServiceCardName,
  CustomDialogServiceDesc,
  CustomServiceStoreImg,
  CustomDialogServiceSocialIcons,
  CustomDialogFollowUsText,
  CustomDialogSocialIconsLink,
};
