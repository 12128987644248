import React from "react";
import { BataiyoLogo } from "../../constant/ImagesS3";
import { ROUTES } from "../../constant/routes";
import "./PageNotFound.css";

function PageNotFound() {
  return (
    <div className="page-not-found-container">
      <header className="page-not-found-header" role="banner">
        <div className="page-not-found-img-container">
          <img src={BataiyoLogo} alt="Bataiyo logo" loading="lazy" />
        </div>
      </header>
      <main role="main">
        <h1 className="not-found-first-heading" id="not-found-title">
          Looking for something?
        </h1>
        <p className="not-found-first-para">
          We're sorry. The web address you entered is not a functioning page on
          our site.
        </p>
        <p className="not-found-second-para">
          Go to{" "}
          <a href={ROUTES.HOME} className="home-link">
            Bataiyo.com
          </a>{" "}
          home page.
        </p>
      </main>
      <footer className="page-not-found-footer" role="contentinfo">
        <p>
          Copyright © {new Date().getFullYear()} Bgent Solutions Private
          Limited. All rights reserved
        </p>
      </footer>
    </div>
  );
}

export default PageNotFound;
