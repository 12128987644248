import CloseIcon from "@mui/icons-material/Close";
import { Dialog, Skeleton, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppleIcon, GoogleIcon, QRCode } from "../../constant/ImagesS3.js";
import {
  dialogSocialIcons,
  downloadAppLinks,
} from "../../constant/staticData.js";
import { useDialog } from "../../context/DialogContext.js";
import { styles } from "./styleSheet.js";

function CustomDialog({ isDialogOpen, handleClose, isSuccessful }) {
  const { handleDialogClose } = useDialog();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [totalImages, setTotalImages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Set the total number of images dynamically based on links
    const total = downloadAppLinks?.length + dialogSocialIcons?.length + 4;
    setTotalImages(total);
  }, []);

  const handleModalClose = () => {
    handleClose();
    handleDialogClose();
  };

  const handleImageLoad = () => {
    setImagesLoaded((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    if (imagesLoaded === totalImages) {
      setTimeout(() => setIsLoading(false), 1500);
    }
  }, [imagesLoaded, totalImages, isLoading]);

  if (!isDialogOpen) return null;

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleModalClose}
      maxWidth="sm"
      fullWidth
      sx={styles.CustomDialogBox(isSmallScreen)}
      slotProps={{
        backdrop: {
          style: styles.backdropStyles,
        },
      }}
      className="success-mui-dialog"
    >
      <div style={styles.CustomDialogContent}>
        <div style={styles.crossButtonBox}>
          <CloseIcon onClick={handleModalClose} sx={styles.crossIcon} />
        </div>
      </div>
      <div style={styles.CustomDialogDescBox}>
        <div style={styles.CutomDialogText}>
          Available on{" "}
          {isLoading ? (
            <>
              <Skeleton variant="circular" width={20} height={20} />
              <Skeleton variant="circular" width={20} height={20} />
            </>
          ) : (
            <>
              <img
                src={AppleIcon}
                alt="apple"
                style={styles.appleIcon}
                onLoad={handleImageLoad}
                loading="lazy"
              />
              <img
                src={GoogleIcon}
                alt="google"
                style={styles.appleIcon}
                onLoad={handleImageLoad}
                loading="lazy"
              />
            </>
          )}
        </div>
        {isSuccessful ? (
          <div style={styles.CustomDialogText}>
            Sign Up Successful!{" "}
            <div style={styles.CustomDialogDescText}>
              Scan QR Code or Download the app from App/Play Store.
            </div>
          </div>
        ) : (
          <div style={styles.CustomDialogText}>
            Get Services from your network.
            <div style={styles.CustomDialogDescText}>
              Join now as a user or service provider.
              <br /> Scan QR Code or Download the app from App/Play Store.
            </div>
          </div>
        )}
        <div style={styles.CustomDialogQrBox}>
          {isLoading ? (
            <Skeleton variant="rectangular" width={200} height={200} />
          ) : (
            <img
              src={QRCode}
              alt="qr-code"
              style={styles.qrCodeImg}
              onLoad={handleImageLoad}
              loading="lazy"
            />
          )}
        </div>
        <div style={styles.CutomDialogImgBox}>
          {downloadAppLinks?.map((store) => (
            <Link key={store?.id} to={store?.url}>
              {isLoading ? (
                <Skeleton variant="rectangular" width={150} height={44} />
              ) : (
                <img
                  style={styles.playStoreImg}
                  src={store?.img}
                  alt={store?.alt}
                  loading="lazy"
                  onLoad={handleImageLoad}
                />
              )}
            </Link>
          ))}
        </div>
        <div>
          <div style={styles.CutomDialogFollowUsText}>FOLLOW US FOR MORE</div>
          <div style={styles.CutomDialogSocialIconsBox}>
            {dialogSocialIcons?.map((item) => (
              <Link
                key={item.id}
                to={item.path}
                target="_blank"
                style={styles.CustomDialogSocialIconsLink}
              >
                {isLoading ? (
                  <Skeleton variant="circular" width={40} height={40} />
                ) : (
                  <img
                    src={item.icon}
                    alt={item.name}
                    style={styles.CustomDialogServiceSocialIcons}
                    onLoad={handleImageLoad}
                    loading="lazy"
                  />
                )}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </Dialog>
  );
}

CustomDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isSuccessful: PropTypes.bool,
};

export default CustomDialog;
