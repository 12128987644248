import { useMediaQuery } from "@mui/material";
import React from "react";
import ContactUsForm from "../../components/forms/ContactUsForm";
import { WhatsAppImg } from "../../constant/ImagesS3";
import { ROUTES } from "../../constant/routes";
import { WhBtnStyles } from "../common/styleSheet";
import "./JobLandingPage.css";
function ContactUsSection() {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  return (
    <section className="contact-us-container" id="contact-us">
      <div className="contact-us-heading mobile-heading">Contact Us</div>
      <div className="contact-us-form-box">
        <div className="contact-us-text-flex">
          <div className="contact-us-heading web-heading">Contact Us</div>
          <div className="contact-us-desc-text">
            Feel free to contact us any time, we will get back to you as soon as
            we can!
          </div>
          <div className="job-social-Icons-box">
            <a
              href={ROUTES.JOBS_WHATS_APP_URL}
              target="_blank"
              rel="noopener noreferrer"
              style={WhBtnStyles.link}
            >
              <img
                src={WhatsAppImg}
                alt="whats-app"
                style={WhBtnStyles.jobWhatsAppImg(isSmallScreen)}
                loading="lazy"
              />
            </a>
            <div className="whats-app-icon">
              Reach Us on Whatsapp!
              <span className="whatsapp-no">+91 8530196306</span>
            </div>
          </div>
        </div>
        <div className="contact-us-flex">
          <ContactUsForm />
        </div>
      </div>
    </section>
  );
}

export default ContactUsSection;
