import React from "react";
import "../../App.css";
import CoreValues from "../../components/CoreValues/CoreValues.jsx";
import FAQ from "../../components/FAQ/FAQ.jsx";
import HeroSection from "../../components/HeroSection/HeroSection";
import RecommendationMatters from "../../components/RecommendationMatters/RecommendationMatters.jsx";
import Roadmap from "../../components/Roadmap/Roadmap.jsx";
import SixDegreeSeperationSection from "../../components/SixDegreeSeperationSection/SixDegreeSeperationSection";
import SuperAppSection from "../../components/SuperAppSection/SuperAppSection.jsx";
import Testimonials from "../../components/Testimonials/Testimonials.jsx";
import WhyBataiyo from "../../components/WhyBataiyo/WhyBataiyo.jsx";
import Layout from "../../Layout.js";
function LandingPage() {
  return (
    <Layout>
      <HeroSection />
      <WhyBataiyo />
      <RecommendationMatters />
      <SixDegreeSeperationSection />
      <Roadmap />
      <CoreValues />
      <Testimonials />
      <SuperAppSection />
      <FAQ />
    </Layout>
  );
}

export default LandingPage;
