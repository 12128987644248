import { MenuItem, Select, Tab, Tabs, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { faqStaticData } from "../../constant/faqStaticData";
import "./FAQ.css";
import FAQAccordion from "./FAQAccordion";

function FAQ() {
  const [activeTab, setActiveTab] = useState(0);
  const [isDropdownVisible, setIsDropdownVisible] = useState(true);
  const faqSectionRef = useRef(null);
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsDropdownVisible(entry.isIntersecting);
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    const faqSection = faqSectionRef.current;
    if (faqSection) {
      observer.observe(faqSection);
    }
    return () => {
      if (faqSection) {
        observer.unobserve(faqSection);
      }
    };
  }, []);

  const transformedData = {
    commonFAQsData: [],
    usersFAQsData: [],
    providerFAQsData: [],
  };

  faqStaticData?.forEach((category) => {
    switch (category?.faqCategory) {
      case "Common":
        transformedData.commonFAQsData = category?.qaPairs;
        break;
      case "User":
        transformedData.usersFAQsData = category?.qaPairs;
        break;
      case "Service":
        transformedData.providerFAQsData = category?.qaPairs;
        break;
      default:
        break;
    }
  });

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleDropdownChange = (event) => {
    setActiveTab(event.target.value);
  };

  return (
    <div className="faq-main-container" id="faq" ref={faqSectionRef}>
      <div className="faq-top-text-box">
        <div>
          <h4 className="faq-first-text">Learn how to get started</h4>
          <h2 className="faq-heading">Frequently Asked Questions</h2>
        </div>
      </div>
      <div className="faq-bottom-box">
        <div className="faq-left-box">
          {isMobile ? (
            isDropdownVisible && (
              <Select
                value={activeTab}
                onChange={handleDropdownChange}
                fullWidth
                variant="outlined"
                sx={{
                  width: "100%",
                  backgroundColor: "#FCFCFD",
                  borderRadius: "14px",
                  fontFamily: "'Lexend', sans-serif",
                  fontSize: "14px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "16px",
                    padding: "0px !important",
                    fontFamily: "'Lexend', sans-serif",
                    fontSize: "14px",
                    borderColor: "#E6E8EC",
                  },
                  "& .MuiSelect-select": {
                    padding: "12px 18px",
                    fontSize: "14px !important",
                    color: "#23262F",
                    fontWeight: 500,
                    fontFamily: "'Lexend', sans-serif",
                  },
                  textAlign: "start",
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      fontFamily: "'Lexend', sans-serif",
                      color: "#323232",
                      fontSize: "14px",
                    },
                  },
                }}
              >
                <MenuItem className="faq-selector-text" value={0}>
                  General
                </MenuItem>
                <MenuItem className="faq-selector-text" value={1}>
                  For Users
                </MenuItem>
                <MenuItem className="faq-selector-text" value={2}>
                  For Service Providers
                </MenuItem>
              </Select>
            )
          ) : (
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              aria-label="FAQ Categories"
              TabIndicatorProps={{
                style: { display: "none" },
              }}
              sx={{
                "& .MuiTabs-flexContainer": {
                  textAlign: "start",
                  alignItems: "start",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  maxWidth: "360px",
                  gap: "40px",
                },
                "& .Mui-selected": {
                  color: "#23262F !important",
                  fontWeight: "500 !important",
                },
              }}
            >
              <Tab label="General" className="faq-tab-text" />
              <Tab label="For Users" className="faq-tab-text" />
              <Tab label="For Service Providers" className="faq-tab-text" />
            </Tabs>
          )}
        </div>
        <div className="faq-right-box">
          {activeTab === 0 && (
            <FAQAccordion data={transformedData?.commonFAQsData} />
          )}
          {activeTab === 1 && (
            <FAQAccordion
              data={transformedData?.usersFAQsData}
              isUsersQue={true}
            />
          )}
          {activeTab === 2 && (
            <FAQAccordion
              data={transformedData?.providerFAQsData}
              isProvidersQue={true}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default FAQ;
