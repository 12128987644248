import { SearchIcon } from "../../constant/ImagesS3";

const ServicesSearchInputBox = ({
  searchQuery,
  handleSearchChange,
  handleSearchFocus,
  handleSearchBlur,
  setSearchQuery,
  setSearchFocused,
}) => {
  return (
    <div className="services-search-box">
      <>
        <img src={SearchIcon} alt="search" loading="lazy" class="search-icon" />
        <input
          type="text"
          placeholder="Services or Keywords"
          className="search-input"
          value={searchQuery}
          onChange={handleSearchChange}
          onFocus={handleSearchFocus}
          onBlur={handleSearchBlur}
          maxLength={20}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              setSearchQuery("");
              setSearchFocused(false);
            }
          }}
        />
        <div className="search-buttons">
          <div className="vertical-border"></div>
          <button
            className="clear-button"
            onClick={() => {
              setSearchQuery("");
              setSearchFocused(false);
            }}
          >
            Clear
          </button>
        </div>
      </>
    </div>
  );
};
export default ServicesSearchInputBox;
