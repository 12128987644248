import { useMediaQuery } from "@mui/material";
import { throttle } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { servicesStaticData } from "../../constant/servicesStaticData";
import useCategoriesAndServices from "../../hooks/useCategoriesAndServices";
import "./AppServices.css";
import CategoriesTabs from "./CategoriesTabs";
import MobileCategoriesTabs from "./MobileCategoriesTabs";
import ServiceList from "./ServiceList";
import ServicesSearchInputBox from "./ServicesSearchInputBox";
import {
  CategoriesTabsSkeleton,
  MobileCategoriesTabsSkeleton,
  SearchInputSkeleton,
  ServiceListSkeleton,
  TopHeadTextSkeleton,
} from "./ServicesSkeleton";

function AppServices() {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchFocused, setSearchFocused] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState("all");
  const [shimmerEffect, setShimmerEffect] = useState(true);
  const [isSticky, setIsSticky] = useState(false);
  const panelRef = useRef(null);
  const topBoxRef = useRef(null);

  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const { categories, loading } = useCategoriesAndServices(servicesStaticData);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShimmerEffect(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!isSmallScreen) return;

    let timeoutId;

    const observer = new IntersectionObserver(
      ([entry]) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          if (entry.isIntersecting) {
            setIsSticky(false);
          } else {
            setIsSticky(true);
          }
        }, 150);
      },
      { threshold: 0.6 }
    );

    if (topBoxRef.current) observer.observe(topBoxRef.current);

    return () => {
      clearTimeout(timeoutId);
      observer.disconnect();
    };
  }, [isSmallScreen]);

  const scrollToTop = () => {
    if (panelRef.current) panelRef.current.scrollTop = 0;
  };

  const mobileScrollToTop = () => {
    if (isSticky && isSmallScreen) {
      window.scrollTo({
        top: 180,
        behavior: "smooth",
      });
    }
  };

  const handleScroll = throttle((event) => {
    const panel = panelRef.current;
    if (!panel || isSmallScreen) return;
    const isAtTop = panel.scrollTop === 0;
    const isAtBottom =
      panel.scrollHeight - panel.scrollTop === panel.clientHeight;

    // Allow container scrolling until it reaches the top or bottom
    if ((isAtTop && event.deltaY < 0) || (isAtBottom && event.deltaY > 0)) {
      event.preventDefault();
      return;
    }
    event.stopPropagation();
  }, 200);

  const handleCategoryChange = (id) => {
    setSearchFocused(false);
    setSearchQuery("");
    setSelectedCategoryId(id);
    scrollToTop();
    mobileScrollToTop();
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleSearchFocus = () => {
    setSearchFocused(true);
    setSelectedCategoryId("all");
    scrollToTop();
    mobileScrollToTop();
  };

  const handleSearchBlur = () => {
    if (!searchQuery) setSearchFocused(false);
  };

  return (
    <div className="services-container">
      {isSmallScreen && isSticky && (
        <>
          <div className="sticky-header mobile-sticky-container">
            <ServicesSearchInputBox
              searchQuery={searchQuery}
              handleSearchChange={handleSearchChange}
              handleSearchFocus={handleSearchFocus}
              handleSearchBlur={handleSearchBlur}
              setSearchQuery={setSearchQuery}
              setSearchFocused={setSearchFocused}
            />
            <MobileCategoriesTabs
              categories={categories}
              selectedCategoryId={selectedCategoryId}
              handleCategoryChange={handleCategoryChange}
            />
          </div>
        </>
      )}

      {/* Top Box */}
      {isSmallScreen ? (
        <>
          <div className="services-top-box" ref={topBoxRef}>
            {shimmerEffect | loading ? (
              <TopHeadTextSkeleton />
            ) : (
              <div className="services-text-box">
                <h3 className="services-subheading">
                  Services Listed on our App
                </h3>
                <h1 className="services-heading">
                  Elevate Every Experience, Simplify your every needs
                </h1>
              </div>
            )}{" "}
          </div>
          <>
            {shimmerEffect | loading ? (
              <div className="mobile-search-container">
                <SearchInputSkeleton />
                <MobileCategoriesTabsSkeleton />
              </div>
            ) : (
              <div className="mobile-search-container">
                <ServicesSearchInputBox
                  searchQuery={searchQuery}
                  handleSearchChange={handleSearchChange}
                  handleSearchFocus={handleSearchFocus}
                  handleSearchBlur={handleSearchBlur}
                  setSearchQuery={setSearchQuery}
                  setSearchFocused={setSearchFocused}
                />
                <MobileCategoriesTabs
                  categories={categories}
                  selectedCategoryId={selectedCategoryId}
                  handleCategoryChange={handleCategoryChange}
                />
              </div>
            )}
          </>
        </>
      ) : (
        <div className="services-top-box">
          {shimmerEffect | loading ? (
            <TopHeadTextSkeleton />
          ) : (
            <div className="services-text-box">
              <h3 className="services-subheading">
                Services Listed on our App
              </h3>
              <h1 className="services-heading">
                Elevate Every Experience, Simplify your every needs
              </h1>
            </div>
          )}
          <>
            {shimmerEffect | loading ? (
              <SearchInputSkeleton />
            ) : (
              <ServicesSearchInputBox
                searchQuery={searchQuery}
                handleSearchChange={handleSearchChange}
                handleSearchFocus={handleSearchFocus}
                handleSearchBlur={handleSearchBlur}
                setSearchQuery={setSearchQuery}
                setSearchFocused={setSearchFocused}
              />
            )}
          </>
        </div>
      )}
      {/* Bottom Box */}
      {!isSmallScreen ? (
        <div className="services-bottom-box">
          <div className="left-panel">
            {shimmerEffect | loading ? (
              <CategoriesTabsSkeleton />
            ) : (
              <CategoriesTabs
                selectedCategoryId={selectedCategoryId}
                handleCategoryChange={handleCategoryChange}
              />
            )}
          </div>
          <div className="right-panel" ref={panelRef} onWheel={handleScroll}>
            {shimmerEffect | loading ? (
              <ServiceListSkeleton />
            ) : (
              <ServiceList
                searchFocused={searchFocused}
                searchQuery={searchQuery}
                selectedCategoryId={selectedCategoryId}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="mobile-bottom-box">
          <div className="right-panel" ref={panelRef} onWheel={handleScroll}>
            {shimmerEffect | loading ? (
              <ServiceListSkeleton />
            ) : (
              <ServiceList
                searchFocused={searchFocused}
                searchQuery={searchQuery}
                selectedCategoryId={selectedCategoryId}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default AppServices;
