import { Grid } from "@mui/material";
import React, { memo, useState } from "react";
import { DefaultServicesIcon } from "../../constant/ImagesS3";
import { servicesStaticData } from "../../constant/servicesStaticData";
import {
  capitalizeWords,
  filterServicesOptions,
  splitServiceNames,
} from "../../constant/utils";
import useCategoriesAndServices from "../../hooks/useCategoriesAndServices";
import SubCategoryList from "./SubCategoryList";
import ViewServiceDialog from "./ViewServiceDialog"; // Assuming you have this component

const ServiceList = memo(
  ({ searchFocused, searchQuery, selectedCategoryId }) => {
    const [isViewServiceDialogOpen, setIsServiceDialogOpen] = useState(false);
    const [isDialogService, setIsDialogService] = useState(null);
    const { categories } = useCategoriesAndServices(servicesStaticData);

    const handleDialogOpen = (service) => {
      setIsDialogService(service);
      setIsServiceDialogOpen(true);
    };

    const filterServices = (services, query) => {
      if (!query) return services;
      const filtered = filterServicesOptions(services, { inputValue: query });
      return Array.from(
        new Map(
          filtered?.map((service) => [
            service._id || service.serviceId,
            service,
          ])
        ).values()
      );
    };

    const selectedCategory = categories?.find(
      (category) => category?.categoryId === selectedCategoryId
    );

    const displaySearchServiceData = Array.from(
      new Map(
        servicesStaticData
          ?.flatMap((category) =>
            category?.subcategories?.flatMap((subcategory) =>
              subcategory?.services?.map((service) => ({
                ...service,
                serviceName: capitalizeWords(service?.serviceName),
              }))
            )
          )
          ?.map((service) => [service.serviceName, service]) // Create a map with unique serviceName as the key
      ).values() // Extract unique services
    ).sort((a, b) => a.serviceName.localeCompare(b.serviceName));

    const servicesToDisplay =
      searchFocused || searchQuery
        ? filterServices(displaySearchServiceData, searchQuery)
        : [];

    const popularServices = categories
      ?.flatMap((category) =>
        category?.subcategories?.flatMap((subcategory) =>
          subcategory?.services?.filter((service) => service?.popular)
        )
      )
      ?.filter(
        (service, index, self) =>
          service &&
          index === self.findIndex((s) => s?.serviceId === service?.serviceId)
      );

    const displayedCategories =
      selectedCategoryId === "all"
        ? categories
        : selectedCategoryId === "popular"
        ? popularServices
        : [selectedCategory];

    return (
      <>
        {servicesToDisplay?.length > 0 ? (
          <div className="all-services-box">
            <Grid container spacing={2}>
              {servicesToDisplay?.map((service, index) => (
                <Grid
                  item
                  key={index}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="custom-grid-padding"
                >
                  <div
                    className="subcategory-items"
                    onClick={() => handleDialogOpen(service)}
                  >
                    <img
                      src={service?.serviceIcon || DefaultServicesIcon}
                      alt={service?.serviceName}
                      className="services-card-img"
                    />
                    <div>{splitServiceNames(service?.serviceName)}</div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        ) : searchQuery ? (
          <div className="all-services-box">
            <Grid container spacing={2}>
              <Grid item xs={12} className="custom-grid-padding">
                Oops! No services match your search.
              </Grid>
            </Grid>
          </div>
        ) : (
          <SubCategoryList
            displayedCategories={displayedCategories}
            searchFocused={searchFocused}
            filterServices={filterServices}
            searchQuery={searchQuery}
            isPopular={selectedCategoryId === "popular"}
          />
        )}

        {/* View Service Dialog */}
        <ViewServiceDialog
          isDialogOpen={isViewServiceDialogOpen}
          handleClose={() => setIsServiceDialogOpen(false)}
          service={isDialogService}
        />
      </>
    );
  }
);

export default ServiceList;
