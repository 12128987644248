import ReactGA from "react-ga4";
import { ROUTES } from "./constant/routes";

export const initGoogleAnalytics = () => {
  ReactGA.initialize(ROUTES.GOOGLE_ANALYTICS_MEASUREMENT_ID);
};

export const trackPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};
