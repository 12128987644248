import CloseIcon from "@mui/icons-material/Close";
import { Button, CircularProgress, Dialog, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { BataiyoLoader, ErrorImg } from "../../constant/ImagesS3";
import { ERROR_MESSAGES } from "../../constant/messages";
import { sendOTP } from "../../services/form.service";
import AlertDialog from "./AlertDialog";
import CustomDialog from "./CustomDialog";
import { OtpField, styles } from "./styleSheet";

const OTPComponent = React.memo(
  ({
    isOpen,
    isClose,
    signUpFormik,
    errorMessage,
    handleErrorFalse,
    error,
  }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [counter, setCounter] = useState(60);
    const [showResendLink, setShowResendLink] = useState(false);
    const [formikOtpError, setFormikOtpError] = useState(false);
    const [resendOtpLoading, setResendOtpLoading] = useState(false);
    const [sendOtpLimitError, setSendOtpLimitError] = useState(false);
    const [sendOtpErrorMessage, setSendOtpErrorMessage] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const signUpFormikRef = useRef(signUpFormik);
    const [successDialog, setSuccessDialog] = useState(false);
    useEffect(() => {
      signUpFormikRef.current = signUpFormik;
    }, [signUpFormik]);

    /* The `useEffect` hook is responsible for handling the scenario where an error occurs with the message "Invalid OTP".*/
    useEffect(() => {
      if (error && errorMessage === "Invalid OTP") {
        // Reset OTP field
        signUpFormikRef.current.setFieldValue("otp", Array(4).fill(""));
        const firstInput = document.getElementById("otp-input-0");
        if (firstInput) {
          firstInput.focus();
        }
      }
    }, [error, errorMessage]);

    /* The `const isOtpValid` is checking if the concatenated string of the OTP values entered by the user is exactly 4 characters long. */
    const isOtpValid =
      signUpFormik?.values?.otp &&
      signUpFormik?.values?.otp.join("").length === 4;

    /* The `formSubmit` function is a callback function created using the `useCallback` hook. It is an asynchronous function that handles the submission of the OTP form.*/
    const formSubmit = useCallback(async () => {
      if (!isOtpValid) {
        setFormikOtpError(true);
        const firstInput = document.getElementById("otp-input-0");
        if (firstInput && signUpFormik?.values?.otp?.length === 0) {
          firstInput.focus();
        }
        return;
      }

      setShowLoader(true);

      const handleDelayedSubmit = async () => {
        try {
          await signUpFormik.validateForm();
          if (Object.keys(signUpFormik?.errors)?.length === 0) {
            const isSuccessful = await signUpFormik.submitForm();
            if (isSuccessful) {
              setSuccessDialog(true);
            }
          }
        } catch (error) {
          console.error(ERROR_MESSAGES?.formSubmitError, error);
        } finally {
          setShowLoader(false);
        }
      };

      setTimeout(handleDelayedSubmit, 2000);
    }, [signUpFormik, isOtpValid]);

    /* The `handleOTPDialogClose` function is a callback function created using the `useCallback` hook. It is responsible for handling the closing of the OTP dialog component.*/
    const handleOTPDialogClose = useCallback(() => {
      isClose();
      handleErrorFalse();
      setFormikOtpError(false);
      signUpFormik.setFieldValue("otp", Array(4).fill(""));
    }, [isClose, signUpFormik, handleErrorFalse]);

    /* This `useEffect` hook is responsible for handling the scenario when the `isOpen` prop changes. */
    useEffect(() => {
      let timeoutId;
      if (isOpen) {
        timeoutId = setTimeout(() => {
          setFormikOtpError(false);
          setCounter(60);
          const firstInput = document.getElementById("otp-input-0");
          if (firstInput) {
            firstInput.focus();
          }
        }, 0);
      }
      return () => {
        clearTimeout(timeoutId);
      };
    }, [isOpen]);

    /* The function `handleOtpChange` updates the OTP input values in a formik form and handles focus on the next input field.*/
    const handleOtpChange = (e, index) => {
      const { value } = e.target;
      if (/^\d$/.test(value) || value === "") {
        handleErrorFalse();
        setFormikOtpError(false);
        const newOtp = [...signUpFormik.values.otp];
        newOtp[index] = value;
        signUpFormik?.setFieldValue("otp", newOtp);
        if (value && index < 3) {
          document.getElementById(`otp-input-${index + 1}`).focus();
        }
      }
    };

    /* The function `handleOtpKeyDown` handles key events for an OTP input field, allowing users to navigate between input fields and submit the form.*/
    const handleOtpKeyDown = (e, index) => {
      const { key } = e;
      const newOtp = [...signUpFormik.values.otp];
      if (key === "Backspace" && index > 0 && !newOtp[index]) {
        document.getElementById(`otp-input-${index - 1}`).focus();
        newOtp[index - 1] = "";
        signUpFormik?.setFieldValue("otp", newOtp);
      } else if (key === "Enter") {
        formSubmit();
      }
    };

    /* The function `handleResendClick` handles the process of resending an OTP for a mobile number verification.*/
    const handleResendClick = async () => {
      // Reset error states
      setResendOtpLoading(true);
      handleErrorFalse();
      setFormikOtpError(false);

      try {
        // Execute reCAPTCHA
        const googleReCaptchToken = await executeRecaptcha();

        // Prepare payload
        const formattedMobileNumber =
          "+91" + signUpFormik?.values?.mobileNumber;
        const payload = {
          captchaToken: googleReCaptchToken,
          mobileNumber: formattedMobileNumber,
        };

        // Send OTP
        await sendOTP(payload);
        setResendOtpLoading(false);

        // Reset OTP field
        signUpFormik.setFieldValue("otp", Array(4).fill(""));

        // Refocus first OTP input field
        const firstInput = document.getElementById("otp-input-0");
        if (firstInput) {
          firstInput.focus();
        }
        // Update UI state
        setShowResendLink(false);
        setCounter(60);
      } catch (error) {
        setResendOtpLoading(false);
        setSendOtpErrorMessage(error?.response?.data?.message);
        setSendOtpLimitError(true);
        console.error("Error resending OTP:", error?.response?.data?.message);
      } finally {
        setResendOtpLoading(false);
      }
    };

    /* The `useEffect` hook you provided is responsible for managing the countdown functionality for resending an OTP in the OTPComponent.*/
    useEffect(() => {
      let timer;
      if (isOpen && counter > 0) {
        timer = setInterval(() => {
          setCounter((prevCounter) => prevCounter - 1);
        }, 1000);
      } else if (counter === 0) {
        setShowResendLink(true);
      }
      return () => clearInterval(timer);
    }, [isOpen, counter]);

    if (!isOpen) return null;

    return (
      <>
        <Dialog
          open={isOpen}
          onClose={(event, reason) => {
            if (reason === "backdropClick") {
              return;
            }
            handleOTPDialogClose();
          }}
          sx={styles.otpDialogContainer}
          slotProps={{
            backdrop: {
              style: styles.backdropStyles,
            },
          }}
          className="otp-dialog"
        >
          <div style={styles.OtpContainer}>
            <div style={styles.OtpHeadingBox}>
              <div>
                <div style={styles.crossIconBox}>
                  <div style={styles.OtpHeading}>Enter OTP and Verify</div>
                  <div>
                    <CloseIcon
                      onClick={handleOTPDialogClose}
                      sx={styles.crossIcon}
                    />
                  </div>
                </div>
                <div style={styles.OtpSubheading}>
                  We sent a one-time password on the given phone number <br />
                  <span style={styles.OtpSubheadingSpan}>
                    +91 {signUpFormik.values.mobileNumber}
                  </span>
                </div>
              </div>
              <div style={styles.OtpInputBox}>
                {Array.from({ length: 4 })?.map((_, index) => (
                  <OtpField
                    key={index}
                    id={`otp-input-${index}`}
                    value={signUpFormik.values.otp[index] || ""}
                    onChange={(e) => handleOtpChange(e, index)}
                    onKeyDown={(e) => handleOtpKeyDown(e, index)}
                    inputProps={{
                      maxLength: 1,
                      inputMode: "numeric",
                    }}
                    error={signUpFormik.touched.otp && signUpFormik.errors.otp}
                    autoComplete="off"
                  />
                ))}
              </div>
              {error && !formikOtpError && (
                <Typography color="error" sx={styles.errorMessagesStyle}>
                  {errorMessage}
                </Typography>
              )}
              {formikOtpError && (
                <Typography color="error" sx={styles.errorMessagesStyle}>
                  Invalid OTP, Please enter a 4-digit OTP.
                </Typography>
              )}
              <div style={styles.OtpDescription}>
                {showResendLink ? (
                  <button
                    type="button"
                    onClick={handleResendClick}
                    style={styles.resendOtpButton}
                    disabled={resendOtpLoading}
                  >
                    {resendOtpLoading ? "Resending..." : "Resend code"}
                  </button>
                ) : (
                  <div>
                    Did not get the message? <br />
                    Resend code in 0:
                    {counter < 10 ? `0${counter}` : counter}
                  </div>
                )}
              </div>
            </div>
            <Button
              type="button"
              sx={styles.OtpButton}
              disabled={signUpFormik?.isSubmitting || showLoader}
              onClick={formSubmit}
            >
              {signUpFormik.isSubmitting ? (
                <>
                  <CircularProgress
                    size={24}
                    style={styles.circularProgressbar}
                  />
                  Submitting...
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </div>{" "}
          {showLoader && (
            <div className="loader-overlay">
              <img
                src={BataiyoLoader}
                alt="bataiyo-loader"
                className="bataiyo-loader-gif"
                loading="lazy"
              />
            </div>
          )}
        </Dialog>
        <AlertDialog
          isDialogOpen={sendOtpLimitError}
          handleClose={() => setSendOtpLimitError()}
          message={sendOtpErrorMessage}
          image={ErrorImg}
        />
        <CustomDialog
          isSuccessful={true}
          isDialogOpen={successDialog}
          handleClose={() => setSuccessDialog(false)}
        />
      </>
    );
  }
);

OTPComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isClose: PropTypes.func.isRequired,
  signUpFormik: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
  handleErrorFalse: PropTypes.func,
  error: PropTypes.bool,
};

export default OTPComponent;
