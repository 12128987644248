import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import React, { useEffect, useRef, useState } from "react";
import { testimonialsData } from "../../constant/staticData";
import "./Testimonials.css";
function Testimonials() {
  const scrollContainerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const updateScrollButtons = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  };

  const scrollToNext = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 300;
      scrollContainerRef.current.scrollBy({
        left: direction === "right" ? scrollAmount : -scrollAmount,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", updateScrollButtons);
      updateScrollButtons();
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", updateScrollButtons);
      }
    };
  }, []);

  return (
    <section className="testimonials-container" id="testimonials">
      <div className="testimonials-inner-box">
      <div className="testimonials-top-text-box">
        <h4 className="testimonials-text">Testimonial</h4>
        <h2 className="testimonials-heading">Don’t take our word for it</h2>
        <h5 className="testimonials-subheading">
          Hear what people have to say about us.{" "}
        </h5>
      </div>
      <div className="testimonials-cards-box" ref={scrollContainerRef}>
        {testimonialsData?.map((item) => (
          <div className="testimonials-card" key={item.id}>
            <div className="testimonial-card-text">{item.description}</div>
            <div className="testimonial-card-profile-box">
              <div className="testimonial-card-profile">
                <img
                  src={item.img}
                  alt={item.role}
                  className="testimonial-card-profile-img"
                  loading="lazy"
                />
              </div>
              <div>
                <div className="testimonial-profile-name">{item.name}</div>
                <div className="testimonial-profile-role">{item.role}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <button
        className={`scroll-button left-button ${
          canScrollLeft ? "active" : "inactive"
        }`}
        onClick={() => scrollToNext("left")}
        disabled={!canScrollLeft}
      >
        <NavigateBeforeIcon />
      </button>
      <button
        className={`scroll-button right-button ${
          canScrollRight ? "active" : "inactive"
        }`}
        onClick={() => scrollToNext("right")}
        disabled={!canScrollRight}
      >
        <NavigateNextIcon />
      </button>
      </div>
    </section>
  );
}

export default Testimonials;
