import React from "react";
import { Link } from "react-router-dom";
import {
  SuperAppAppStoreImg,
  SuperAppDownloadImg,
  SuperAppPlayStoreImg,
} from "../../constant/ImagesS3";
import { ROUTES } from "../../constant/routes";
import "./SuperAppSection.css";
function SuperAppSection() {
  return (
    <section className="super-app-container">
      <div className="super-app-box">
        <div className="super-app-heading-box">
          <h3 className="super-app-heading">
            Unlock the potential of your network with our SUPER APP
          </h3>
          <h6 className="super-app-subheading">
            What are you waiting for? Download the app now and get help from
            your network with zero commission!
          </h6>
        </div>
        <div className="super-app-store-img-box">
          <Link to={ROUTES.APP_STORE_URL}>
            <img
              src={SuperAppAppStoreImg}
              alt="app-store"
              className="super-app-store-img"
              loading="lazy"
            />
          </Link>
          <Link to={ROUTES.PLAY_STORE_URL}>
            <img
              src={SuperAppPlayStoreImg}
              alt="play-store"
              className="super-app-store-img"
              loading="lazy"
            />
          </Link>
        </div>
        <img
          src={SuperAppDownloadImg}
          alt="super-app"
          className="super-app-sec-img"
          loading="lazy"
        />
      </div>
    </section>
  );
}

export default SuperAppSection;
