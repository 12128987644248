import React, { useLayoutEffect } from "react";
import JobAppSection from "../../components/AppFeatures/JobAppSection";
import ContactUsSection from "../../components/JobLandingPage/ContactUsSection";
import DashboardScreenshot from "../../components/JobLandingPage/DashboardScreenshot";
import HireCategoriesImgSection from "../../components/JobLandingPage/HireCategoriesImgSection";
import JobHeroSection from "../../components/JobLandingPage/JobHeroSection";
import Seo from "../../components/SEO/Seo";
import JobHowBataiyoIsDifferent from "../../components/TheBataiyoAdvantage/JobHowBataiyoIsDifferent";
import { HireCategoriesImg } from "../../constant/ImagesS3";
import { jobsSeoKeywords } from "../../constant/staticData";
import Layout from "../../Layout";
function JobLandingPage() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Seo
        title="Bataiyo | Jobs"
        description="Unlock your potential with Bataiyo Jobs! Explore a diverse range of job openings across industries such as IT, healthcare, finance, education, marketing, and more. Discover remote, hybrid, and on-site opportunities tailored to your skills and preferences. Connect with top employers, enhance your career with personalized job recommendations, and take the next step toward landing your dream job today!"
        keywords={jobsSeoKeywords?.join(", ")}
        url="jobs"
      />
      <JobHeroSection />
      <DashboardScreenshot />
      <div className="categories-lg-box">
        <HireCategoriesImgSection />
      </div>
      <div className="advantage-scroller-box">
        <JobHowBataiyoIsDifferent />
        <div className="hire-category-img-box">
          <img
            src={HireCategoriesImg}
            alt="categories"
            className="hire-category-img"
            loading="lazy"
          />
        </div>
      </div>
      <JobAppSection />
      <ContactUsSection />
    </Layout>
  );
}
export default JobLandingPage;
