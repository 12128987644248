import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import React, { useEffect, useRef, useState } from "react";
import {
  GrowingTogetherIcon,
  NetworkMattersIcon,
  TrustFirstIcon,
} from "../../constant/ImagesS3";
import "./CoreValues.css";

function CoreValues() {
  const scrollContainerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const updateScrollButtons = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  };

  const scrollToNext = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 300;
      scrollContainerRef.current.scrollBy({
        left: direction === "right" ? scrollAmount : -scrollAmount,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", updateScrollButtons);
      updateScrollButtons();
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", updateScrollButtons);
      }
    };
  }, []);

  return (
    <section className="core-values-container" id="core-values">
      <div className="core-values-top-text-box">
        <h4 className="core-values-matters-text">Core Values</h4>
        <h2 className="core-values-heading">
          The Values that hold us true and to account
        </h2>
      </div>
      <div className="core-values-cards-box" ref={scrollContainerRef}>
        <div className="core-values-card">
          <div className="core-values-card-icon-box">
            <img
              src={NetworkMattersIcon}
              alt="network-matters"
              className="core-values-network-icon"
              loading="lazy"
            />
          </div>
          <div className="core-values-card-heading">Networking Matters</div>
          <div className="core-values-card-description">
            {/* Your network is your strength—solving everyday challenges together through recommendations. */}
            {/* The power of your network is the most valuable resource you have. */}
            We believe that the power of your network is the most valuable
            resource for any help you may need.
          </div>
        </div>

        <div className="core-values-card">
          <div className="core-values-card-icon-box">
            <img
              src={TrustFirstIcon}
              alt="trust-first"
              className="core-values-trust-icon"
              loading="lazy"
            />
          </div>
          <div className="core-values-card-heading">Trust First</div>
          <div className="core-values-card-description">
            {/* Trust is earned, not bought. We value honesty above all. Trust is earned, not bought. We value honesty above all. */}
            We believe in the power of honesty. It’s worth more than any review
            system. Trust is earned, not bought.
          </div>
        </div>

        <div className="core-values-card">
          <div className="core-values-card-icon-box">
            <img
              src={GrowingTogetherIcon}
              alt="growing-together"
              className="core-values-growing-icon"
              loading="lazy"
            />
          </div>
          <div className="core-values-card-heading">Growing Together</div>
          <div className="core-values-card-description">
            {/* Strong relationships last, creating a legacy of support and success. Trust is earned, not bought. We value honesty above all. */}
            We believe that strong relationships last forever and should not be
            lost. Therefore, creating a legacy of support and success is
            important.
          </div>
        </div>
      </div>
      <button
        className={`core-scroll-button core-left-button ${
          canScrollLeft ? "active" : "inactive"
        }`}
        onClick={() => scrollToNext("left")}
        disabled={!canScrollLeft}
      >
        <NavigateBeforeIcon />
      </button>
      <button
        className={`core-scroll-button core-right-button ${
          canScrollRight ? "active" : "inactive"
        }`}
        onClick={() => scrollToNext("right")}
        disabled={!canScrollRight}
      >
        <NavigateNextIcon />
      </button>
    </section>
  );
}

export default CoreValues;
