import { useMediaQuery } from "@mui/material";
import React from "react";
import { RoadmapDotIcon } from "../../constant/ImagesS3";
import { roadmapCardsDetails } from "../../constant/staticData";
import "./Roadmap.css";
function Roadmap() {
  const isSmallScreen = useMediaQuery("(max-width: 767px)");

  return (
    <section className="roadmap-section" id="roadmap">
      <div className="roadmap-top-text-box">
        <h4 className="roadmap-subheading-text">Vision</h4>
        <h2 className="roadmap-heading">Our Roadmap</h2>
      </div>
      {isSmallScreen ? (
        <div className="roadmap-mobile-container">
          <div className="horz-blue-line"></div>
          {roadmapCardsDetails?.map((item) => (
            <div className="roadmap-mobile-horz-box" key={item?.id}>
              <div className="roadmap-card-qtext-box">
                <div className="roadmap-mobile-phase-text">{item?.title}</div>
                <div className="roadmap-card-qtext">{`Q${item?.q}-${item?.year}`}</div>
              </div>
              <div className="roadmap-image-container">
                <img
                  src={RoadmapDotIcon}
                  alt={item?.alt}
                  className="roadmap-dot-image"
                  loading="lazy"
                />
              </div>
              <div className="roadmap-card-qtext-box">
                <div className="roadmap-card-desc">{item?.details}</div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="roadmap-box-container">
          <div className="horz-blue-line"></div>{" "}
          {roadmapCardsDetails?.map((item) => (
            <div className="roadmap-card-container" key={item?.id}>
              <div className="roadmap-card-title">{item?.title}</div>
              <div className="roadmap-image-container">
                <img
                  src={RoadmapDotIcon}
                  alt={item?.alt}
                  className="roadmap-dot-image"
                  loading="lazy"
                />
              </div>
              <div className="roadmap-card-qtext-box">
                <div className="roadmap-card-qtext">{`Q${item?.q}-${item?.year}`}</div>
                <div className="roadmap-card-desc">{item?.details}</div>
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
}

export default Roadmap;
