export const faqStaticData = [
  {
    faqCategory: "Service",
    qaPairs: [
      {
        question: "Is there a fee for listing my services on Bataiyo?",
        answer: "No.",
        _id: "666c0fe417b4605ca9be8402",
      },
      {
        question: "How do recommendations work?",
        answer:
          "Users recommend your services to their network, boosting visibility and credibility.",
        _id: "666c0fe417b4605ca9be8403",
      },
      {
        question: 'What is "Quick Check" Feature?',
        answer:
          "It helps you to quickly win user's trust by checking if you already exist in their network.",
      },
      {
        question: "How can I build trust in Bataiyo?",
        answer:
          "Provide excellent service and encourage satisfied clients to leave positive feedback.",
        _id: "666c0fe417b4605ca9be8404",
      },
      {
        question: "What are the benefits of being in Bataiyo?",
        answer:
          "Increased visibility, trusted recommendations, and access to a community of potential clients.",
        _id: "666c0fe417b4605ca9be8405",
      },
      {
        question: "How can Bataiyo help me grow my business?",
        answer:
          "Bataiyo helps you reach a wider audience through personal recommendations and user feedback, allowing you to grow your customer base and increase your business opportunities.",
        _id: "666c0fe417b4605ca9be8406",
      },
      {
        question: "Can I track my performance on Bataiyo?",
        answer:
          "Yes, you can track your performance by going to the feedback section (Coming Soon) of the app.",
        _id: "666c0fe417b4605ca9be8407",
      },
    ],
  },
  {
    faqCategory: "User",
    qaPairs: [
      {
        question: "How do I find service providers?",
        answer:
          "Search for the type of service you need and see recommendations from your network.",
        _id: "666c0f9917b4605ca9be8409",
      },
      {
        question: "How are service providers recommended?",
        answer:
          "Service Providers are recommended by your friends and their network, ensuring trust and reliability.\n",
        _id: "666c0f9917b4605ca9be8410",
      },
      {
        question:
          "While searching for Service providers I am seeing Friends +1, +2 and +3. What are those?",
        answer:
          "These numbers represent the level of connections through which the service provider is getting recommended from, i.e. +1 means friends of friends connection, +2 signifies that the service provider is recommended by your second level of connections and so on and so forth. ",
        _id: "666c0f9917b4605ca9be8411",
      },
      {
        question: "How can I search services for other locations?",
        answer:
          "You can either change your current location for quick results or adjust your location when searching for services.",
        _id: "666c0f0417b4605ca9be83fe",
      },
      {
        question: "What is “Quick Check” Feature?",
        answer:
          "It helps you quickly check if someone is already in your trusted network so that you can connect hassle free and obtain discounts.",
        _id: "666c0f4817b4605ca9be8412",
      },
      {
        question: "Can I use “Quick Check” to check User to User connection?",
        answer: "Yes",
        _id: "666c0f9917b4605ca9be8413",
      },
      {
        question: "Can I leave reviews for service providers?",
        answer:
          "We don’t have a rating system but you can leave your feedback for the provider.",
        _id: "666c0f9917b4605ca9be8414",
      },
      {
        question: "How do I know if a service provider is trustworthy?",
        answer:
          "Trustworthiness is based on recommendations and feedback from people within your network.",
        _id: "666c0f9917b4605ca9be8415",
      },
      {
        question: "Can I see the feedback left by others?",
        answer:
          "Yes, you can view feedback left by other users in your network to make informed decisions.",
        _id: "666c0f9917b4605ca9be8416",
      },
      {
        question:
          "I am concerned about my privacy, especially regarding the phone number. Service providers are going to spam me if they have my cell phone number. ",
        answer:
          "Trust us, we hate spams more than you. Sharing phone number with the service provider is optional, once you have finalised the service provider you can toggle the switch to share your number with the service provider. Team Bataiyo will not bother you for sure. ",
        _id: "666c0f9917b4605ca9be8417",
      },
    ],
  },
  {
    faqCategory: "Common",
    qaPairs: [
      {
        question: "What is Bataiyo?",
        answer:
          "Bataiyo is an application that helps users explore and connect with service providers recommended by their personal connections.",
        _id: "666c0cce17b4605ca9be83e7",
      },
      {
        question: "How does Bataiyo work?",
        answer:
          "Bataiyo leverages your personal connections and their recommendations to provide reliable service providers in your new city. When your connections recommend a service provider, you can view and connect with them through the app.",
        _id: "666c0cce17b4605ca9be83e8",
      },
      {
        question:
          "How much commission does Bataiyo charge from users and service providers?",
        answer: "Money can not buy Trust. So Zero Commission !!",
        _id: "666c0cce17b4605ca9be83e9",
      },
      {
        question: "How is Bataiyo different?",
        answer:
          "We are not a service providers app, we help you get quick and trusted recommendations from your trusted network.",
        _id: "666c0e5217b4605ca9be8310",
      },
      {
        question: "Is Bataiyo free to use?",
        answer:
          "Yes, Bataiyo is free for users to find and recommend service providers.",
        _id: "666c0e5217b4605ca9be8311",
      },
      {
        question: "In which cities is Bataiyo available?",
        answer:
          "Bataiyo is currently available in multiple cities and towns across India. Check the app for the full list of supported cities.",
        _id: "666c0e5217b4605ca9be8312",
      },
      {
        question: "How do I sign up for Bataiyo?",
        answer:
          "Download the Bataiyo app from the App Store or Google Play, and follow the on-screen instructions to create an account using your phone number.",
        _id: "666c0e5217b4605ca9be8313",
      },
      {
        question: "How do I manage my account?",
        answer:
          "Manage your account settings, recommendations, and feedback through your user dashboard.",
        _id: "666c0e5217b4605ca9be8314",
      },
      {
        question: "How do I update my profile information?",
        answer:
          "You can update your profile information through your account settings on the Bataiyo app.",
        _id: "666c0e5217b4605ca9be8315",
      },
      {
        question: "How can I get help if I have an issue with the app?",
        answer:
          "You can access the help section within the app for FAQs and support articles. If you need further assistance, contact Bataiyo’s customer support through the app or by email.",
        _id: "666c0e5217b4605ca9be8316",
      },
      {
        question: "How is my personal information protected on Bataiyo?",
        answer:
          "Bataiyo uses robust security measures to protect your personal information and ensures it is only shared within your network as necessary.",
        _id: "666c0e8f17b4605ca9be83f9",
      },
      {
        question: "Can I invite friends to join Bataiyo?",
        answer:
          "Yes, you can invite friends to join and expand your trusted network.",
        _id: "666c0f9917b4605ca9be8422",
      },
      {
        question: "How do I contact Bataiyo for support?",
        answer:
          "Contact Bataiyo support through the help section on the Bataiyo app.",
        _id: "666c0f9917b4605ca9be8423",
      },
    ],
  },
];
