import React, { useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Route, Routes, useLocation } from "react-router-dom";
import { trackPageView } from "./analytics";
import "./App.css";
import AcceptCookiesBanner from "./components/AcceptCookiesBanner/AcceptCookiesBanner";
import WhatsAppButton from "./components/common/WhatsAppButton";
import { ROUTES } from "./constant/routes";
import { ApiDataProvider } from "./context/ApiDataContext";
import { DialogProvider } from "./context/DialogContext";
import useCookieConsent from "./hooks/useCookieConsent";
import useScroll from "./hooks/useScroll";
import useWhatsAppBanner from "./hooks/useWhatsAppBanner";
import routes from "./routes/routes";

const App = () => {
  const { initialLoading } = useScroll();
  const location = useLocation();
  const { cookieAccepted, bannerVisible, handleAcceptCookies } =
    useCookieConsent();
  const { whBannerVisible } = useWhatsAppBanner();

  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);

  if (initialLoading) {
    return null;
  }
  const showWhatsAppButton =
    location.pathname !== "/app" && location.pathname !== "/jobs";
  // const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  return (
    <div className="App" data-scroll-container>
      <ApiDataProvider>
        <DialogProvider>
          <GoogleReCaptchaProvider
            reCaptchaKey={ROUTES.RECAPTCHA_SITE_KEY}
            scriptProps={{
              async: false,
              defer: false,
              appendTo: "head",
              nonce: undefined,
              theme: "dark",
            }}
          >
            <Routes>
              {routes?.map((route) => (
                <Route
                  key={route?.id}
                  path={route?.path}
                  element={route?.element}
                />
              ))}
            </Routes>
            {bannerVisible && !cookieAccepted && (
              <AcceptCookiesBanner onAccept={handleAcceptCookies} />
            )}
            {showWhatsAppButton && whBannerVisible && <WhatsAppButton />}
          </GoogleReCaptchaProvider>
        </DialogProvider>
      </ApiDataProvider>
    </div>
  );
};

export default App;
