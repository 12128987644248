import { useEffect, useState } from "react";
import { getCookieConsent, setCookieConsent } from "../constant/utils";

const useCookieConsent = () => {
  const [cookieAccepted, setCookieAccepted] = useState(false);
  const [bannerVisible, setBannerVisible] = useState(false);

  /* The `useEffect` hook in the provided code snippet is responsible for handling the logic related to cookie consent.*/
  useEffect(() => {
    const consent = getCookieConsent();
    if (consent) {
      setCookieAccepted(true);
      setBannerVisible(false);
    } else {
      // Show the banner immediately
      setBannerVisible(true);

      // The following code for delay and scroll event has been commented out
      // as we want to show the banner instantly.
      /*
      // Show the banner after a delay of 20 seconds
      const delayTimeoutId = setTimeout(() => {
        setBannerVisible(true);
      }, 20000);

      // Add scroll event listener to show the banner when user scrolls below 100vh
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const viewportHeight = window.innerHeight;
        if (scrollPosition > viewportHeight) {
          setBannerVisible(true);
          // Once shown, remove the scroll listener
          window.removeEventListener("scroll", handleScroll);
        }
      };
      window.addEventListener("scroll", handleScroll);
      // Cleanup the timeout on component unmount
      return () => {
        clearTimeout(delayTimeoutId);
        window.removeEventListener("scroll", handleScroll);
      };
      */
    }
  }, []);

  const handleAcceptCookies = () => {
    setCookieConsent();
    setCookieAccepted(true);
    setBannerVisible(false);
  };

  return {
    cookieAccepted,
    bannerVisible,
    handleAcceptCookies,
  };
};

export default useCookieConsent;
