import { useEffect, useState } from "react";

function useScroll() {
  const [initialLoading, setInitialLoading] = useState(true);

  /* This `useEffect` hook is performing several tasks related to scrolling behavior and attribute manipulation in a React component.*/
  useEffect(() => {
    let scroll;
    import("locomotive-scroll").then((locomotiveModule) => {
      scroll = new locomotiveModule.default({
        el: document.querySelector("[data-scroll-container]"),
        smooth: true,
        smoothMobile: false,
        resetNativeScroll: true,
      });
    });

    // Function to handle attributes and style changes
    const handleAttributesAndScroll = () => {
      const elementsToAddOverflow = [
        ".success-mui-dialog",
        ".otp-dialog",
      ];
      const elementsToAddAttribute = [
        { className: ".success-mui-dialog", name: "success-dialog" },
        { className: ".otp-dialog", name: "otp-popover" },
        { className: ".MuiDialog-root", name: "popover" },
        { className: ".MuiAutocomplete-popper", name: "selectorPopover" },
        { className: ".MuiDrawer-root", name: "navDrawer" },
        { className: ".MuiPickersPopper-paper", name: "datePicker" },
      ];

      // Check if either `.success-mui-dialog` or `.otp-dialog` is present
      const isOverflowRequired = elementsToAddOverflow.some((selector) =>
        document.querySelector(selector)
      );

      // Update `html.lenis` overflow property
      const htmlElement = document.querySelector("html.lenis");
      if (htmlElement) {
        htmlElement.style.overflow = isOverflowRequired ? "hidden" : "";
      }

      // Add `data-lenis-prevent` attribute to matching elements
      elementsToAddAttribute.forEach((element) => {
        const el = document.querySelector(element.className);
        if (el) {
          switch (element.name) {
            case "success-dialog":
            case "otp-popover":
            case "popover":
            case "selectorPopover":
            case "navDrawer":
            case "datePicker":
              el.setAttribute("data-lenis-prevent", "");
              break;
            default:
              console.warn(`No action defined for ${element.name}`);
          }
        }
      });
    };

    // Create a MutationObserver instance
    const observer = new MutationObserver(() => {
      handleAttributesAndScroll();
    });

    // Start observing the body for changes
    observer.observe(document.body, { childList: true, subtree: true });

    // Set initial loading to false after a timeout
    const loadingTimeout = setTimeout(() => {
      setInitialLoading(false);
    }, 300);

    // Cleanup
    return () => {
      if (scroll) scroll.destroy();
      observer.disconnect();
      const htmlElement = document.querySelector("html.lenis");
      if (htmlElement) {
        htmlElement.style.overflow = "";
      }
      clearTimeout(loadingTimeout);
    };
  }, []);

  return { initialLoading };
}

export default useScroll;
